import { useEffect, useState } from "react";
import api from "../lib/api";
import { toast } from "sonner";

const useUserOrders = ({id}) => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
     try {
        const { data } = await api.get(`/admin/orders/user/${id}`);
        setOrders(data.orders);
     } catch (error) {
        toast.error(error.response.data.message);
     }

    };
    fetchOrders();
  }, [id]);

  return {orders};
};

export default useUserOrders;
