import React from 'react';
import  formatTime  from '../../lib/formatTime';

function SentMessage({ message, date }) {
  return (
    <div className='w-full flex justify-end'>

        <div className='flex flex-col bg-secondary p-2 rounded-md'>
            <span>{message}</span>
            <span className='text-xs font-extralight'>{formatTime(date)}</span>
        </div>


    </div>
  )
}

export default SentMessage