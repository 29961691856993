import { useContext, useState } from "react";
import api from "../lib/api";
import { toast } from "sonner";
import { UsersContext } from "../context/Users";

function useNewUser() {
  const { appendUser } = useContext(UsersContext);

  const [user, newUser] = useState({
    name: "",
    email: "",
    lastname: "",
  });

  const handleChange = (e) => {
    newUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user.name === "" || user.lastname === "" || user.email === "") {
      toast.error("Todos los campos son obligatorios");
      return;
    }

    try {
      const result = await api.post("/admin/users", user);
      toast.success("Usuario creado");
      e.target.reset();
      appendUser(result.data.user);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
    }
  };

  return {
    user,
    handleChange,
    handleSubmit,
  };
}

export default useNewUser;
