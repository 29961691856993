import React from "react";
import UsersSearch from "../components/users/UsersSearch";
import { Button, Input } from "@nextui-org/react";
import SelectChest from "../components/chests/SelectChest";
import useNewOrder from "../hooks/useNewOrder";
import OrderItem from "../components/order/OrderItem";

export default function CreateOrder() {
  const {
    newOrder,
    setCustomer,
    pushChest,
    handleKeyQuantity,
    handleDiscount,
    handleSubmit
  } = useNewOrder();

  const subtotal = newOrder.chests.reduce(
    (acc, curr) => acc + curr.quantity * curr.price,
    0
  );

  const discount = subtotal * (newOrder.discount / 100);

 

  return (
    <form onSubmit={(e) => handleSubmit(e)} className="flex flex-col pt-12 items-center h-screen">
      <div className="absolute bottom-0 left-0">{JSON.stringify(newOrder)}</div>

      <div className="w-1/6 flex flex-col">
        <UsersSearch handleSelectedUser={setCustomer} />
      </div>

      <div className="w-1/6 flex flex-col mt-8">
        <SelectChest setSelectedChest={pushChest} />

        <div className="text-left mt-4 ">
          {newOrder.chests.map((chest) => (
            <OrderItem key={chest.id} chest={chest} handleKeyQuantity={handleKeyQuantity} customer={newOrder.customer} quantity={newOrder.chests.find((key) => key.id === chest.id).quantity} />
          ))}
        </div>
      </div>

      <div className="w-1/6 mt-8">
        <Input
          className="mt-4"
          type="decimal"
          name="discount"
          label="Descuento"
          placeholder="Porcentaje de descuento"
          onInput={handleDiscount}
        />
      </div>

      <div className="w-1/6 flex flex-col mt-8">
        <div className="flex justify-between">
          <strong className="text-success">Subtotal:</strong>${" "}
          {subtotal.toFixed(2)}
        </div>

        <div className="flex justify-between">
          <strong className="text-success">Descuento:</strong>${" "}
          {discount.toFixed(2)}
        </div>

        <div className="flex justify-between">
          <strong className="text-success">Total:</strong>${" "}
          {(subtotal - discount).toFixed(2)}
        </div>

        <span className="text-xs text-warning mt-3">
            Se enviará el cobro al correo del cliente
          </span>
      </div>

      <Button type="submit" className="w-1/6 mt-4" color="primary">
        <strong>Crear orden</strong>
      </Button>
    </form>
  );
}
