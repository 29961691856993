import { Input } from "@nextui-org/react";
import React, { useContext } from "react";
import { BiSearch } from "react-icons/bi";
import { UsersFiltersContext } from "../../context/UsersFilters";

function UsersFilters() {

    const {setUsersFilters} = useContext(UsersFiltersContext)


  return (
    <div className="flex gap-3 items-center">
      <Input
        onChange={(e) => setUsersFilters((prev) => ({ ...prev, search: e.target.value }))}
        type="text"
        placeholder="Buscar"
        endContent={<BiSearch />}
        className="w-full h-full"
      />

    </div>
  );
}

export default UsersFilters;
