import { useCallback, useState } from "react";
import { toast } from "sonner";
import api from "../lib/api";
import { useNavigate } from "react-router-dom";
const useNewOrder = () => {
  const navigate = useNavigate();
  const [newOrder, setNewOrder] = useState({
    customer: null,
    chests: [],
    discount: 0,
  });

  const setCustomer = (customer) => {
    setNewOrder({
      ...newOrder,
      customer: customer.id,
    });
  };

  const pushChest = useCallback(
    (newChest) => {
      // if key already exist, increase quantity

      const formatedKey = {
        id: newChest.id,
        name: newChest.name,
        price: newChest.price,
        imgId: newChest.imgId,
        quantity: 1,
      };

      const newChestsArray = [...newOrder.chests];
      const index = newChestsArray.findIndex((key) => key.id === newChest.id);
      if (index > -1) {
        newChestsArray[index].quantity++;
      } else {
        newChestsArray.push(formatedKey);
      }
      setNewOrder({
        ...newOrder,
        chests: newChestsArray,
      });
    },
    [newOrder]
  );

  const handleKeyQuantity = (id, quantity) => {
    const newChestsArray = [...newOrder.chests];
    const index = newChestsArray.findIndex((key) => key.id === id);

    if (parseInt(quantity) === 0) {
      newChestsArray.splice(index, 1);
    } else {
      newChestsArray[index].quantity = quantity;
    }

    setNewOrder({
      ...newOrder,
      chests: newChestsArray,
    });
  };


  const handleDiscount = (e) => {
    if (e.target.value === "") {
      setNewOrder({
        ...newOrder,
        discount: 0,
      });
      return;
    }
    setNewOrder({
      ...newOrder,
      discount: parseInt(e.target.value),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newOrder.customer) {
      toast.error("Selecione un cliente");
      return;
    }
    if (newOrder.chests.length === 0) {
      toast.error("Agregue al menos una clave");
      return;
    }
    if (newOrder.paymentMethod === null) {
      toast.error("Seleccione un metodo de pago");
      return;
    }

    try {
      const { data } = await api.post("/admin/orders", newOrder);
      toast.loading("Procesando orden");
      
    

      const pulling = setInterval(async () => {
        const { data: result } = await api.get(
          `/admin/orders/queue/${data.job}`
        );

        if (result.state === "completed") {
          clearInterval(pulling);
          toast.success("Orden procesada");
          navigate("/orders")
        } else if (result.state === "failed") {
          clearInterval(pulling);
          toast.error(result.message);
        } 
      }, 1000);


    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return {
    newOrder,
    setNewOrder,
    setCustomer,
    pushChest,
    handleKeyQuantity,
    handleDiscount,
    handleSubmit,
  };
};

export default useNewOrder;
