import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/table";
import { Avatar, Button, Tooltip } from "@nextui-org/react";
import React, { useContext } from "react";
import { AiFillCheckCircle } from "react-icons/ai";

import { FaExclamationCircle } from "react-icons/fa";
import formatTime from "../../lib/formatTime";
import { EyeIcon } from "../icons/Eye";
import { EditIcon } from "../icons/Edit";
import { Link } from "react-router-dom";
import { ChestsContext } from "../../context/Chests";

function ChestsTable() {
  const { chests } = useContext(ChestsContext);
  return (
    <Table className="mt-12 h-[60vh] overflow-y-scroll">
      <TableHeader className="">
        <TableColumn className="text-left">Imagen</TableColumn>
        <TableColumn className="text-left">Nombre</TableColumn>
        <TableColumn className="text-left">Precio</TableColumn>
        <TableColumn className="text-left">Activo</TableColumn>
        <TableColumn className="text-left">Stock</TableColumn>
        <TableColumn className="text-left">Creado</TableColumn>
        <TableColumn className="text-left">Acciones</TableColumn>
      </TableHeader>
      <TableBody>
        {chests &&
          chests.map((chest) => (
            <TableRow className={` rounded `} key={chest.id}>
              <TableCell className="py-4 small">
                <div className="flex items-center gap-4">
                  <Avatar
                    src={process.env.REACT_APP_CDN_URL + "/" + chest.imgId}
                    size="large"
                    className="bg-none"
                  />
                </div>
              </TableCell>

              <TableCell className="py-4 small">{chest.name}</TableCell>
              <TableCell className="py-4 small">${chest.price}</TableCell>
              <TableCell className="py-4 small">
                {chest.status ? (
                  <Tooltip content="Activo">
                    <Button
                      variant="transparent"
                      className="w-fit h-fit bg-none"
                    >
                      <AiFillCheckCircle className="text-green-500" />
                    </Button>
                  </Tooltip>
                ) : (
                  <Tooltip content="Inactivo">
                    <Button
                      variant="transparent"
                      className="w-fit h-fit bg-none "
                    >
                      <FaExclamationCircle className="text-red-500" />
                    </Button>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell className="py-4 small">{chest.stock}</TableCell>
              <TableCell className="py-4 small">
                {formatTime(chest.createdAt)}
              </TableCell>

              <TableCell className="py-4 small">
                <div className="relative flex items-center gap-2">
                  <Tooltip content="Ver">
                    <Link
                      to={`/chest/${chest.id}`}
                      className="text-lg text-default-400 cursor-pointer active:opacity-50"
                    >
                      <EyeIcon />
                    </Link>
                  </Tooltip>
                  <Tooltip content="Editar">
                    <Link
                      to={`/chest/${chest.id}`}
                      className="text-lg text-default-400 cursor-pointer active:opacity-50"
                    >
                      <EditIcon />
                    </Link>
                  </Tooltip>
                </div>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}

export default ChestsTable;
