import React, { useEffect } from "react";
import startUsersChart from "../../lib/charts/users";
import useUsers from "../../hooks/useUsers";

function UsersChart() {

    const {users} = useUsers();


  useEffect(() => {
    startUsersChart(users);
  }, [users]);

  return <div id="users-chart"></div>;
}

export default UsersChart;
