
const useGoogleAuth = () => {
  async function openAuth() {
    const url = "/user/auth/google";
    window.location.href = process.env.REACT_APP_API_URL + url;
  }

  return { openAuth };
};

export default useGoogleAuth;
