import React from 'react'
import DiscountsTable from '../components/discounts/DiscountsTable'
import useDiscounts from '../hooks/useDiscounts'
import NewDiscount from '../components/discounts/NewDiscount'

function Discounts() {
    const discounts = useDiscounts()
  return (
    <>
    <main className='flex px-4 gap-8 items-center'>
    <DiscountsTable discounts={discounts} />
    <NewDiscount />
    </main>
    </>
  )
}

export default Discounts