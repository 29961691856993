import { useEffect, useState } from "react";
import api from "../lib/api";

const usePayment = (id) => {
    const [payment, setPayment] = useState(null);
    useEffect(() => {
        const fetchPayment = async () => {
        const {
            data
        } = await api.get(`/checkout/${id}`);
        setPayment(data.payment);
        };
    
        fetchPayment();
    }, [id]);
    return {
        payment,
    };
}

export default usePayment;