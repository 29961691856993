import React from 'react'
import { FcGoogle } from "react-icons/fc";
import useGoogleAuth from '../../hooks/useGoogleAuth';

function GoogleBtn() {

  const {openAuth} = useGoogleAuth()

  return (
    <button
    type="button"
    class="flex w-full justify-center items-center gap-3 rounded-md bg-gray-100 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100 mt-4"
    onClick={()=> openAuth()}
  >
    <FcGoogle size={20} />
    Sign in with Google
  </button>
  )
}

export default GoogleBtn