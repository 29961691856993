import React from 'react'
import KeyCard from './KeyCard'

function KeysList({keys}) {
  return (
    <ul className='flex flex-col gap-4 max-h-[85vh] overflow-y-scroll'>
        {keys.map((key) => (
            <li key={key.id}>
             <KeyCard propKey={key} key={key.id} />
            </li>
        ))}
    </ul>
  )
}

export default KeysList