import React, { useEffect } from "react";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Button,
} from "@nextui-org/react";
import { KEY_STATUS } from "../../lib/const";
import api from "../../lib/api";
import { toast } from "sonner";

export default function KeyStatus({ propKey }) {
  const [status, setStatus] = React.useState(propKey.status);

  useEffect(() => {
    setStatus(propKey.status);
  }, [propKey]);

  const handleStatus = async (status) => {
    try {
      const result = await api.put(`/admin/key/${propKey.id}/status/${status}`);
      toast.success("Estado actualizado");

      setStatus(result.data.key.status);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <Dropdown>
      <DropdownTrigger>
        <Button
          variant="bordered"
          color={
            status === KEY_STATUS.ACTIVE
              ? "success"
              : status === KEY_STATUS.DEMAGED
              ? "danger"
              : "warning"
          }
        >
          {status === KEY_STATUS.ACTIVE && "Activa"}
          {status === KEY_STATUS.INACTIVE && "Inactiva"}
          {status === KEY_STATUS.DEMAGED && "Dañada"}
        </Button>
      </DropdownTrigger>
      <DropdownMenu aria-label="Static Actions">
        <DropdownItem
          onClick={() => handleStatus(KEY_STATUS.ACTIVE)}
          color="success"
          key="new"
        >
          Activa
        </DropdownItem>
        <DropdownItem
          onClick={() => handleStatus(KEY_STATUS.INACTIVE)}
          color="warning"
          key="new"
        >
          Inactiva
        </DropdownItem>
        <DropdownItem
          onClick={() => handleStatus(KEY_STATUS.DEMAGED)}
          color="danger"
          key="new"
        >
          Dañada
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}
