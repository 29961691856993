import React, { useEffect } from "react";
import startBillingChart from "../../lib/charts/billing";
import useOrders from "../../hooks/useOrders";

function BillingChart() {
  const { orders } = useOrders();

  useEffect(() => {
    startBillingChart(orders);
  }, [orders]);

  if (!orders) return null;

  return <div id="billing-chart"></div>;
}

export default BillingChart;
