import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import useOrder from "../hooks/useOrder";
import { Button } from "@nextui-org/react";
import api from "../lib/api";
import { toast } from "sonner";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

const stripePromise = loadStripe("pk_test_51MlSUkDOmPjCdiDDO5iTsWMrj6olorrfoF6C8fsaN01xOuGk23Lh6c5wB79bR0w5S7hRS5QoRTi5OwxwXYeAYOAM00CwlhH559");

function Checkout() {
  const { id } = useParams();

  const [paymentMethod, setPaymentMethod] = React.useState(0);

  const { order } = useOrder(id);

  const handlePaymentMethod = (e) => {
    setPaymentMethod(parseInt(e.target.value));
  };

  if (!order) return null;

  return (
    <div className="p-8">
      <select
        onChange={(e) => handlePaymentMethod(e)}
        name=""
        id=""
        className="p-3 rounded"
      >
        <option value="">Método de pago</option>
        <option value={0}>Transferencia/SINPE</option>
        <option value={1}>PayPal</option>
        <option value={2}>Stripe</option>
      </select>
      {paymentMethod === 0 && <ManualMethod order={order} />}
      {paymentMethod === 1 && <PayPalMethod order={order} />}
      {paymentMethod === 2 && <StripeMethod order={order} />}
    </div>
  );
}

function OrderTotals({ order }) {
  return (
    <div className=" flex flex-col mt-8">
      <div className="flex justify-between">
        <strong className="text-success">Subtotal:</strong>${" "}
        {order.subtotal.toFixed(2)}
      </div>

      <div className="flex justify-between">
        <strong className="text-success">Descuento:</strong>- ${" "}
        {(order.subtotal * (order.discount / 100)).toFixed(2)}
      </div>

      <div className="flex justify-between">
        <strong className="text-success">Total:</strong>${" "}
        {(order.subtotal - order.subtotal * (order.discount / 100)).toFixed(2)}
      </div>
    </div>
  );
}

function ManualMethod({ order }) {
  const [paymentData, setPaymentData] = React.useState({
    voucher: null,
  });

  const handleFile = (e) => {
    setPaymentData({
      ...paymentData,
      [e.target.name]: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await api.post(`/checkout/${order.id}/manual`, paymentData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col mt-8 w-1/6">
      <label htmlFor="voucher" className="text-gray-500">
        Comprobante de pago
      </label>
      <input
        id="voucher"
        onInput={handleFile}
        type="file"
        name="voucher"
        className="file-input w-full max-w-xs"
      />

      <OrderTotals order={order} />

      <Button type="submit" className="my-4" color="success" size="small">
        Enviar comprobante
      </Button>

      <span className="text-warning text-xs">
        Un administrador revisará el pago y aprobará la orden a la brevedad
      </span>
    </form>
  );
}

function PayPalMethod({ order }) {
  const navigate = useNavigate();
  async function createOrder() {
    const { data } = await api.post(`/checkout/${order.id}/paypal`);
    return data.id;
  }

  async function onApprove(data) {
    try {
      await api.post(`/checkout/${order.id}/paypal/capture`, {
        token: data.orderID,
      });
      toast.loading("Procesando pago");

      setTimeout(() => {
        toast.success("Pago realizado con éxito");
        navigate(`/order/${order.id}`);
      }, 3000);
    } catch (error) {
      toast.error("Proceso de pago fallido");
    }
  }

  function onError(err) {
    console.log(err);
    toast.error("Proceso de pago fallido");
  }

  function onCancel() {
    toast.warning("Proceso de pago cancelado");
  }

  return (
    <PayPalScriptProvider
      options={{
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
      }}
    >
      <div className="w-1/6">
        <PayPalButtons
          onCancel={onCancel}
          onError={onError}
          createOrder={createOrder}
          onApprove={onApprove}
          className="w-1/6 p-2 bg-black"
          style={{ layout: "vertical" }}
        />
        <OrderTotals order={order} />
      </div>
    </PayPalScriptProvider>
  );
}

function StripeMethod({ order }) {
  const options = {
    mode: "payment",
    currency: "usd",
    amount:
      parseFloat(
        (order.subtotal - order.subtotal * (order.discount / 100)).toFixed(2)
      ) * 100,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <StripeForm order={order} />
    </Elements>
  );
}

function StripeForm({ order }) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      // obtener tarjeta de PaymentElement
      card: elements.getElement(CardElement),
    });

    
    if (error) {
      toast.error(error.message);
      return;
    }

    try {
      const { data } = await api.post(`/checkout/${order.id}/stripe`, {
        token: paymentMethod.id,
      });

      if (!data.success) {
        toast.error(data.message);
        return;
      }

      navigate(`/order/${order.id}`);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="w-1/6">
      <div className="bg-white mt-4 h-8">
        <CardElement />
      </div>
      <OrderTotals order={order} />
      <Button onClick={handleSubmit} className="my-4 w-full" color="success">
        Pagar
      </Button>
    </div>
  );
}

export default Checkout;
