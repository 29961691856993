const { useState } = require("react");
const { default: api } = require("../lib/api");
const { useEffect } = require("react");
const { toast } = require("sonner");

const useChats = () => {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const { data } = await api.get("/admin/chats");
      setChats(data.chats);
      setLoading(false);
      } catch (error) {
        toast.error('Error fetching chats');
      }
    };

    fetchChats();
  }, []);

  return { chats, loading, setChats };
};

export default useChats;
