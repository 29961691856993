import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/table";
import { Button, Tooltip } from "@nextui-org/react";
import React, { useContext } from "react";
import { UsersContext } from "../../context/Users";
import { AiFillCheckCircle } from "react-icons/ai";

import { FaExclamationCircle } from "react-icons/fa";
import formatTime from "../../lib/formatTime";
import UserRoles from "./UserRoles";
import { EyeIcon } from "../icons/Eye";
import { EditIcon } from "../icons/Edit";
import { Link } from "react-router-dom";

function UsersTable() {
  const { users } = useContext(UsersContext);
  return (
    <Table className="mt-12 h-[60vh] overflow-y-scroll">
      <TableHeader className="">
        <TableColumn className="text-left">Nombre</TableColumn>
        <TableColumn className="text-left">Correo</TableColumn>
        <TableColumn className="text-left">Roles</TableColumn>
        <TableColumn className="text-left">Baneado</TableColumn>
        <TableColumn className="text-left">Creado</TableColumn>
        <TableColumn className="text-left">Acciones</TableColumn>
      </TableHeader>
      <TableBody>
        {users &&
          users.map((client) => (
            <TableRow className={` rounded `} key={client.id}>
              <TableCell className="py-4 small">
                {client.name} {client.lastName}
              </TableCell>
              <TableCell className="py-4 small">{client.email}</TableCell>
              <TableCell className="py-4 small">
               <UserRoles roles={client.roles} />
              </TableCell>
              <TableCell className="py-4 small">
                {!client.banned ? (
                  <Tooltip  content="No baneado">
                    <Button variant="transparent" className="w-fit h-fit bg-none">
                    <AiFillCheckCircle className="text-green-500" />
                    </Button>
                  </Tooltip>
                ) : (
                  <Tooltip  content="Baneado">
                  <Button variant="transparent" className="w-fit h-fit bg-none ">
                  <FaExclamationCircle className="text-red-500" />
                  </Button>
                </Tooltip>
                  
                )}
              </TableCell>
              <TableCell className="py-4 small">{formatTime(client.createdAt)}</TableCell>

              <TableCell className="py-4 small">
              <div className="relative flex items-center gap-2">
            <Tooltip content="Ver">
              <Link to={`/user/${client.id}`} className="text-lg text-default-400 cursor-pointer active:opacity-50">
                <EyeIcon />
              </Link>
            </Tooltip>
            <Tooltip content="Editar">
              <Link to={`/user/${client.id}`} className="text-lg text-default-400 cursor-pointer active:opacity-50">
                <EditIcon />
              </Link>
            </Tooltip>
          </div>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}

export default UsersTable;
