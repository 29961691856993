import React, { useContext } from "react";
import { FaCheckCircle,  FaExclamationCircle } from "react-icons/fa";
import { ChestsFiltersContext } from "../../context/ChestsFilters";
import { GiChest, GiOpenChest } from "react-icons/gi";
function ChestsMenu({ setActiveUI, activeUI }) {
  const { chestsFilters, setChestsFilters } = useContext(ChestsFiltersContext);
  return (
    <ul className="menu menu-vertical  rounded-box">
      <li>
        <span>Interfaz</span>

        <span
          onClick={() => setActiveUI({ ...activeUI, table: !activeUI.table })}
          className={activeUI.table && "text-green-500"}
        >
          <GiChest className="h-5 w-5" />
          Cofres
        </span>
      </li>
      <li>
        <span
          onClick={() => setActiveUI({ ...activeUI, add: !activeUI.add })}
          className={activeUI.add && "text-green-500"}
        >
          <GiOpenChest color="" className="h-5 w-5" />
          Agregar
        </span>
      </li>

      <li>
        <span>Estado</span>

        <div
          className="flex items-center  gap-2"
          onClick={() =>
            setChestsFilters((prev) => ({ ...prev, active: !prev.active }))
          }
        >
          {chestsFilters.active ? (
            <>
              <FaCheckCircle className="text-green-500" />
              <span>Activo</span>
            </>
          ) : (
            <>
              <FaExclamationCircle className="text-red-500" />
              <span>Inactivo</span>
            </>
          )}
        </div>
      </li>
    </ul>
  );
}

export default ChestsMenu;
