import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Users from "./views/Users";
import Login from "./views/Login";
import { Toaster } from "sonner";
import NotFound from "./views/NotFound";
import Panel from "./views/Panel";
import User from "./views/User";
import Chests from "./views/Chests";
import Chest from "./views/Chest";
import CreateOrder from "./views/CreateOrder";
import Orders from "./views/Orders";
import Order from "./views/Order";
import Checkout from "./views/Checkout";
import Chats from "./views/Chats";
import Chat from "./views/Chat";
import Discounts from "./views/Discounts";

function App() {
  return (
    <>
      <Toaster theme="dark" richColors />
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Panel />} />
          <Route path="/login" element={<Login />} />
          <Route path="/panel" element={<Panel />} />
          <Route path="/users" element={<Users />} />
          <Route path="/user/:id" element={<User />} />
          <Route path="/chests" element={<Chests />} />
          <Route path="/chest/:id" element={<Chest />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/order/new" element={<CreateOrder />} />
          <Route path="/order/:id" element={<Order />} />
          <Route path="/checkout/:id" element={<Checkout />} />
          <Route path="/chats/" element={<Chats />} />
          <Route path="/chats/:id" element={<Chat />} />
          <Route path="/discounts" element={<Discounts />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
