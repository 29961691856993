import React, { useEffect } from "react";
import KeyCard from "../order/KeyCard";
import api from "../../lib/api";
import { toast } from "sonner";
function OrderKeys({ order }) {
  const [keys, setKeys] = React.useState([]);

  useEffect(() => {
    const fetchOrderKeys = async () => {
      try {
        const { data } = await api.get(`/admin/orders/${order.id}/keys`);
        setKeys(data.keys);
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    fetchOrderKeys();
  }, [order]);

  return (
    <div className='w-[20vw] max-h-screen overflow-y-scroll'>
      {keys.map((key) => (
        <KeyCard key={key.id} propKey={key} />
      ))}
    </div>
  );
}

export default OrderKeys;
