import { createContext, useEffect, useState } from "react";
import api from "../lib/api";
import { toast } from "sonner";

const ChestsContext = createContext();

const ChestsProvider = ({ children }) => {
  const [chests, setChests] = useState([]);

  useEffect(() => {
    const fetchChests = async () => {
      try {
        const result = await api.get("/admin/chests");
        setChests(result.data);
      } catch (error) {
        toast.error("Ocurrió un error al obtener los cofres");
      }
    };

    fetchChests();
  }, []);

  return (
    <ChestsContext.Provider value={{ chests, setChests }}>
      {children}
    </ChestsContext.Provider>
  );
};

export { ChestsContext, ChestsProvider };
