import { useContext, useState } from "react";
import { toast } from "sonner";
import api from "../lib/api";
import { useParams } from "react-router-dom";
import { ChestKeysContext } from "../context/ChestKeys";

const useNewKey = () => {
  const { setKeys } = useContext(ChestKeysContext);
  const [newKey, setNewKey] = useState({
    key: [],
    uses: null,
  });
  const [individualKey, setIndividualKey] = useState();
  const { id } = useParams();

  const handleChange = (e) => {
    setNewKey({
      ...newKey,
      [e.target.name]: e.target.value,
    });
  };

  const handleNewKeyIndividual = (e) => {
    setIndividualKey(e.target.value);
  };

  const handleNewKey = () => {
    setNewKey({
      ...newKey,
      key: newKey.key.concat(individualKey),
    });

    setIndividualKey("");
  };

  const handleDeleteKey = (key) => {
    const newKeys = newKey.key.filter((k) => k !== key);
    setNewKey({
      ...newKey,
      key: newKeys,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newKey.key.length === 0 || !newKey.uses) {
      toast.error("Todos los campos son requeridos");
      return;
    }

    newKey.key.forEach(async (key) => {
      const data = {
        key,
        uses: newKey.uses,
      };

      try {
        const result = await api.post(`/admin/chests/${id}/key`, data);

        if (result.data.success) {
          setKeys((prevKeys) => [...prevKeys, result.data]);
          setNewKey({
            key: [],
            uses: null,
          });
          e.target.reset();
        } else {
          toast.error("Ocurrió un error al crear la clave");
        }
      } catch (error) {
        toast.error("Ocurrió un error al crear la clave");
      }
    });
    toast.success("Clave creada correctamente");
  };

  return {
    handleChange,
    handleSubmit,
    handleNewKey,
    newKey,
    handleNewKeyIndividual,
    individualKey,
    handleDeleteKey,
  };
};

export default useNewKey;
