// Importa las dependencias necesarias
import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../context/User";
import RecivedMessage from "../components/chat/ReceivedMessage";
import SentMessage from "../components/chat/SentMessage";
import { Button, Input } from "@nextui-org/react";
import { IoSend } from "react-icons/io5";
import useChat from "../hooks/useChat";
import { User } from "@nextui-org/react";
import chatSocket from "../lib/chatSocket";
import { ChatContext } from "../context/Chats";
import { useParams } from "react-router-dom";
import ChatsPanel from "../components/chat/ChatsPanel";

const Chat = () => {
  const { id } = useParams();
  const [newMessage, setNewMessage] = useState("");
  const { user } = useContext(UserContext);
  const { chat, messages, setMessages } = useChat({ chatId: id });
  const { markAsRead } = useContext(ChatContext);



  useEffect(() => {
    scrollToBottom();
    if (!chat ) return;
    markAsRead(chat.id);
    chatSocket.on("message", (message) => {
      // Actualiza el estado de los mensajes cuando se recibe un nuevo mensaje
      if (message.chatId !== chat.id) return;
      setMessages((prevMessages) => [...prevMessages, message]);
      markAsRead(message.chatId);
      scrollToBottom();
    });
  }, [setMessages, markAsRead, chat]); // El array vacío asegura que este efecto solo se ejecute una vez al montar el componente

  const sendMessage = () => {
    if (!newMessage) return;
    // Envía un mensaje al servidor a través de Socket.io
    chatSocket.emit("message", { newMessage, chatId: chat.id, to: chat.user });
    setMessages((prevMessages) => [
      ...prevMessages,
      { message: newMessage, user: user.id, date: new Date() },
    ]);

    scrollToBottom();
    // Limpia el campo de nuevo mensaje
    setNewMessage("");
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      const messagesRender = document.getElementById("messages-render");
      if (messagesRender) {
        messagesRender.scrollTo(0, messagesRender.scrollHeight);
      }
    }, 100);
  };

  if (!chat || !user) return null;

  return (
    <section className="flex w-3/4 m-auto gap-8">
      <ChatsPanel />
      <section className="w-full m-auto">
        <div>
          <User name={chat.name + " " + chat.lastName} />
        </div>

        <div
          id="messages-render"
          className="h-[80vh] overflow-y-scroll flex flex-col gap-3"
        >
          {messages.map((message, index) => {
            if (message.user === user.id) {
              return (
                <SentMessage
                  key={index}
                  message={message.message}
                  date={message.createdAt}
                />
              );
            } else {
              return (
                <RecivedMessage
                  key={index}
                  message={message.message}
                  date={message.createdAt}
                />
              );
            }
          })}
        </div>

        <div className="flex gap-2 mt-4">
          <Input
            placeholder="Mensaje"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
          />
          <Button color="secondary" onClick={sendMessage}>
            <IoSend />
          </Button>
        </div>
      </section>
    </section>
  );
};

export default Chat;
