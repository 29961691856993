import { Input } from "@nextui-org/react";
import React from "react";
import { BiRename } from "react-icons/bi";
import { MdOutlinePriceCheck } from "react-icons/md";
import useNewChest from "../../hooks/useNewChest";

function AddChest() {
  const { handleChange, handleSubmit, handleFile } = useNewChest();

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4" action="">
      <Input
        onChange={handleChange}
        type="text"
        name="name"
        label="Nombre"
        placeholder="Nombre"
        endContent={<BiRename />}
      />

      <Input
        onChange={handleChange}
        type="decimal"
        name="price"
        label="Precio"
        placeholder="Precio"
        endContent={<MdOutlinePriceCheck />}
      />

      <input
        onInput={handleFile}
        type="file"
        name="img"
        className="file-input w-full max-w-xs"
      />

      <button type="submit" className="btn btn-primary w-full">
        Crear
      </button>
    </form>
  );
}

export default AddChest;
