import { Divider } from "@nextui-org/react";
import React from "react";
import formatTime from "../../lib/formatTime";
import { ORDER_STATUS } from "../../lib/const";
import { Link } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";

function OrderCard({ order }) {
  return (
    <div key={order.id} className="pb-2 w-full rounded ">
      <div className="grid grid-cols-4 md:grid-cols-5 gap-4">
        <div>
          <p className="text-xs text-secondary">N° de Orden: </p>
          <p className="text-sm">{order.id}</p>
        </div>

        <div>
          <p className="text-xs text-secondary">Fecha:</p>
          <p className="text-sm">{formatTime(order.createdAt)}</p>
        </div>

        <div className="hidden md:block">
          <p className="text-xs text-secondary">Total:</p>
          <p className="text-sm">
            {(order.subtotal - (order.subtotal * order.discount) / 100).toFixed(
              2
            )}{" "}
            USD
          </p>
        </div>
        <div>
          <p className={`text-xs text-${ORDER_STATUS[order.status].color}`}>
            {ORDER_STATUS[order.status].name}
          </p>
        </div>
        <div>
          <Link to={`/order/${order.id}`}>
            <AiOutlineEye className="text-gray-500" />
          </Link>
        </div>
      </div>
      <Divider className="mt-2" />
    </div>
  );
}

export default OrderCard;
