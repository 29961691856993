import React, { useContext } from "react";
import { ChestContext } from "../../context/Chest";
import { AiFillCheckCircle } from "react-icons/ai";
import { FaExclamationCircle } from "react-icons/fa";

function ChestCard() {
  const { chest } = useContext(ChestContext);

  if (!chest) return null;

  return (
    <div className="flex gap-8 w-unit-9xl">
      <img
        className="h-60 rounded-2xl"
        src={process.env.REACT_APP_CDN_URL + "/" + chest.imgId}
        alt=""
      />
      <div>
        <h3 className="font-bold text-3xl">{chest.name}</h3>
        <div className="flex gap-4">
        <span className="text-gray-500">{chest.price} USD</span>
        <div>
          {chest.status ? (
            <span className="flex items-center gap-2">
              <AiFillCheckCircle className="text-green-500" />
              Activo
            </span>
          ) : (
            <span className="flex items-center gap-2">
              <FaExclamationCircle className="text-red-500" />
              Inactivo
            </span>
          )}
        </div>
        </div>
      </div>
    </div>
  );
}

export default ChestCard;
