import React from "react";
import { OrdersProvider } from "../context/Orders";
import OrdersTable from "../components/orders/OrdersTable";

function Orders() {
  return (
    <OrdersProvider>
        <main className="px-12">
        <OrdersTable />
        </main>
    </OrdersProvider>
  );
}

export default Orders;
