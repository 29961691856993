import { User } from "@nextui-org/react";
import React, { useContext } from "react";
import { SelectedUserContext } from "../../context/SelectedUser";

function UserProfile() {
  const { selectedUser } = useContext(SelectedUserContext);

  if (!selectedUser) return null;
  return (
    <div>
      <User
        name={selectedUser.name + " " + selectedUser.lastName}
        description={selectedUser.email}
      />

      {selectedUser.banned ? (
        <div className="bg-red-500 text-white rounded-box p-1 text-center text-xs">
          Usuario Baneado
        </div>
      ) : null}
    </div>
  );
}

export default UserProfile;
