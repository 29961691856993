import React from "react";
import { Tabs, Tab } from "@nextui-org/react";
import NewKey from "./NewKey";
import KeysList from "./KeysList";
import { KEY_STATUS } from "../../lib/const";

export default function KeysTabs({ keys }) {
  const keyslist = {
    available: keys.filter((keys) => keys.uses > 0 && keys.status === KEY_STATUS.ACTIVE),
    deliveried: keys.filter((keys) => keys.uses === 0 && keys.status === KEY_STATUS.ACTIVE),
    inactives: keys.filter((keys) => keys.status === KEY_STATUS.INACTIVE),
    demaged: keys.filter((keys) => keys.status === KEY_STATUS.DEMAGED),
  }
  return (
    <div className="flex w-full flex-col">
      <Tabs color="primary" aria-label="Options">
        <Tab key="active" title="Disponibles">
          <KeysList keys={keyslist.available} />
        </Tab>
        <Tab key="deliveried" title="Agotadas">
          <KeysList keys={keyslist.deliveried} />
        </Tab>
        <Tab key="inactives" title="Inactivas">
          <KeysList keys={keyslist.inactives} />
        </Tab>
        <Tab key="demaged" title="Dañadas">
          <KeysList keys={keyslist.demaged} />
        </Tab>
        <Tab key="new" title="Nueva">
          <NewKey />
        </Tab>
      </Tabs>
    </div>
  );
}
