import { createContext, useEffect, useRef, useState } from "react";
import api from "../lib/api";
import { toast } from "sonner";
//import { UsersFiltersContext } from "./UsersFilters";
//import { useCallback } from "react";

const UsersContext = createContext();

const UsersProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const originalUsers = useRef([]);
  const [loading, setLoading] = useState(true);
  //const { usersFilters } = useContext(UsersFiltersContext);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const res = await api.get("/admin/users");
        setUsers(res.data.users);
        originalUsers.current = res.data.users;
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
        console.log(error);
      }
    };

    getUsers();
  }, []);

  // const filterUsers = useCallback(() => {
  //   const filterUsers = (users) => {
  //     const { search, role, banned } = usersFilters;

  //     return users.filter((user) => {
  //       const searchMatch =
  //         search === "" ||
  //         user.name.toLowerCase().includes(search.toLowerCase());
  //       const roleMatch = role === "all" || user.roles.includes(role);
  //       const bannedMatch = !banned || user.banned;

  //       return searchMatch && roleMatch && bannedMatch;
  //     });
  //   };
  //   const filteredUsers = filterUsers(originalUsers.current);
  //   setUsers(filteredUsers);
  // }, [usersFilters]);

  useEffect(() => {
    //filterUsers();
  }, []);

  const appendUser = (user) => {
    setUsers((prev) => [...prev, user]);
    originalUsers.current = [...originalUsers.current, user];
  };

  return (
    <UsersContext.Provider value={{ users, setUsers, loading, appendUser }}>
      {children}
    </UsersContext.Provider>
  );
};

export { UsersProvider, UsersContext };
