import React, { useContext } from "react";
//import KeysStats from "./KeysStats";
import KeysTabs from "./KeysTabs";
import { ChestKeysContext } from "../../context/ChestKeys";

function Keys() {
  const { keys } = useContext(ChestKeysContext);


  if (!keys) return <p>Loading...</p>;

  return (
    <div className="w-full">
      <header>
        {/* <KeysStats /> */}
      </header>

      <section>
        <KeysTabs keys={keys} />
      </section>
    </div>
  );
}

export default Keys;
