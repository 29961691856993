import React from "react";
import ChestMenu from "../components/chest/ChestMenu";
import ChestCard from "../components/chest/ChestCard";
import { ChestProvider } from "../context/Chest";
import EditChest from "../components/chest/EditChest";
import Keys from "../components/keys/Keys";
import { ChestKeysProvider } from "../context/ChestKeys";

function Chest() {
  const [activeUI, setActiveUI] = React.useState({
    keys: true,
    edit: false,
  });
  return (
    <ChestProvider>
      <main className="flex">
        <aside>
          <ChestMenu setActiveUI={setActiveUI} activeUI={activeUI} />
        </aside>

        <div className="w-full mt-4 px-8">
          <header className="flex items-center">
            {/* <UsersStats />
          <UsersFilters /> */}
          </header>
          <section className="w-full flex flex-col md:flex-row justify-between gap-12 ">
            <ChestCard />
            <ChestKeysProvider>
              <Keys />
            </ChestKeysProvider>
            {activeUI.edit && <EditChest />}
          </section>
        </div>
      </main>
    </ChestProvider>
  );
}

export default Chest;
