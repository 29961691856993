import React from "react";
import { AcmeLogo } from "../components/AcmeLogo";
import { Link } from "react-router-dom";
import { Divider } from "@nextui-org/react";
import GoogleBtn from "../components/Auth/GoogleBtn";
import useLogin from "../hooks/useLogin";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";

function Login() {
  const {handleChange, handleSubmit, loading} = useLogin()
  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <div class="flex min-h-screen flex-col justify-center px-6 py-12 lg:px-8">
      <div class="sm:mx-auto flex justify-center sm:w-full sm:max-w-sm">
        <AcmeLogo />
      </div>

      <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form class="space-y-6" action="#" method="POST">
          <div>
            <label
              for="email"
              class="block text-sm font-medium leading-6 "
            >
              Email address
            </label>
            <div class="mt-2">
              <input
                onChange={(e) => handleChange(e)}
                id="email"
                name="email"
                type="email"
                autocomplete="email"
                required
                placeholder="Email"
                class="block w-full rounded-md border-0 py-1.5 p-2  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div class="flex items-center justify-between">
              <label
                for="password"
                class="block text-sm font-medium leading-6 "
              >
                Password
              </label>
              <div class="text-sm">
                <Link to="/recovery" class="font-semibold text-secondary">
                   ¿Olvidaste tu contraseña?
                </Link>
              </div>
            </div>
            <div class="mt-2 flex gap-2">
              <input
                onChange={(e) => handleChange(e)}
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                autocomplete="password"
                required
                placeholder="Contraseña"
                class="block w-full rounded-md border-0 py-1.5 p-2  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
              />
              <button
                onClick={() =>
                  setShowPassword(!showPassword)
                }
                type="button"
              >
                {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
              </button>
            </div>
          </div>

          <div>
            <button
              onClick={(e) => handleSubmit(e)}
              type="button"
              class="flex w-full justify-center rounded-md bg-secondary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
               <span className={loading && "loading loading-spinner"}></span>
                {loading ? "Cargando" : "Entrar"}
            </button>
          </div>
        </form>

        <Divider color="light" />

        <div>
          <GoogleBtn />
        </div>

        <p class="mt-10 text-center text-sm text-gray-500">
          ¿No tienes una cuenta?{" "}
          <Link to="/register" class="font-semibold leading-6 text-secondary">
            Crea una nueva
          </Link>
        </p>
      </div>
    </div>
  );
}

export default Login;
