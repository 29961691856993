import React, { useContext } from "react";
import { FiUsers } from "react-icons/fi";
import { PiFolderUserDuotone } from "react-icons/pi";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import { SelectedUserContext } from "../../context/SelectedUser";
import { ROLES } from "../../lib/const";
function UserMenu({ setActiveUI, activeUI }) {
  const {selectedUser} = useContext(SelectedUserContext);

  if (!selectedUser) return null;
  return (
    <ul className="menu menu-vertical  rounded-box">
  
      <li>
        <span>Interfaz</span>

          <span

            onClick={() =>
              setActiveUI({ ...activeUI, profile: !activeUI.profile,  })
            }
            className={activeUI.profile && "text-green-500"}
          >
            <FiUsers className="h-5 w-5" />
            Usuario
          </span>
        
      </li>
      <li>
        <span
          onClick={() =>
            setActiveUI({ ...activeUI, edit: !activeUI.edit })
          }
        className={activeUI.edit && "text-green-500"}
        >
          <AiOutlineEdit color="" className="h-5 w-5" />
          Editar
        </span>
      </li>

      <li>
        <span>Roles</span>

        <div className="flex flex-col items-start gap-2">
          {selectedUser.roles.includes(ROLES.USER) && <div className="flex items-center  gap-2">
            <AiOutlineUser className={"text-orange-500"} />
            <span>Usuario</span>
          </div>}

          {selectedUser.roles.includes(ROLES.DEALER) && <div className="flex items-center  gap-2">
            <PiFolderUserDuotone className={" text-green-600"} />
            <span>Mayorista</span>
          </div>}

         {selectedUser.roles.includes(ROLES.ADMIN) && <div className="flex items-center  gap-2">
            <MdOutlineAdminPanelSettings className={" text-purple-600"} />

            <span>Admin</span>
          </div>}
        </div>
      </li>
    </ul>
  );
}

export default UserMenu;
