import {
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow,
  } from "@nextui-org/table";
  import React from "react";
  
  import formatTime from "../../lib/formatTime";
  
  function DiscountsTable({ discounts }) {
    return (
      <Table className="mt-12 h-[60vh] overflow-y-scroll">
        <TableHeader className="">
          <TableColumn className="text-left">Código</TableColumn>
          <TableColumn className="text-left">Porcentaje %</TableColumn>
          <TableColumn className="text-left">Usos</TableColumn>
          <TableColumn className="text-left">Expira</TableColumn>
          <TableColumn className="text-left">Creado</TableColumn>
        </TableHeader>
        <TableBody>
          {discounts &&
            discounts.map((discount) => (
              <TableRow className={` rounded `} key={discount.id}>
                <TableCell className="py-4 small">
                  {discount.code}
                </TableCell>
                <TableCell className="py-4 small">
                  {discount.discount}
                </TableCell>
                <TableCell className="py-4 small">{discount.uses}</TableCell>
                <TableCell className="py-4 small">
                {formatTime(discount.expirationDate)}
                </TableCell>
                <TableCell className="py-4 small">{formatTime(discount.createdAt)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    );
  }
  
  export default DiscountsTable;
  