import { useEffect, useState } from "react";
import api from "../lib/api";

const useKeyUses = (key) => {
  const [keyUses, setKeyUses] = useState([]);

  useEffect(() => {
    const fetchUses = async () => {
      const { data } = await api.get(`/admin/key/${key}/uses`);
      setKeyUses(data);
    };
    fetchUses();
  }, [key]);

  return {keyUses};
};

export default useKeyUses;