import { Button, Card, CardBody, CardHeader, Divider, Image } from '@nextui-org/react'
import React from 'react'
import useDecryptKey from '../../hooks/useDecryptKey'

function KeyCard({propKey}) {

    const {decriptedKey, decryptKey, encryptKey} = useDecryptKey(propKey.key)

  return (
    <Card className="max-w-[440px]">
      <CardHeader className="flex gap-3">
        <Image
          alt="nextui logo"
          height={40}
          radius="sm"
          src={process.env.REACT_APP_CDN_URL + "/" + propKey.imgId}
          width={40}
        />
        <div className="flex flex-col">
          <p className="text-md">{propKey.name}</p>
          <p className="text-small text-default-500">{propKey.id}</p>
        </div>
      </CardHeader>
      <Divider />
      <CardBody>
        <p className="text-center">{decriptedKey || "*************"}</p>
        {!decriptedKey ? (
          <Button
            className="mt-2"
            color="primary"
            size="small"
            variant="solid"
            onClick={decryptKey}
          >
            Desencriptar
          </Button>
        ) : (
          <Button
            className="mt-2"
            color="primary"
            size="small"
            variant="solid"
            onClick={encryptKey}
          >
            Encriptar
          </Button>
        )}
      </CardBody>
      <Divider />

    </Card>
  )
}

export default KeyCard