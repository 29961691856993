import { useContext, useEffect, useState } from "react";
import { SelectedUserContext } from "../context/SelectedUser";
import api from "../lib/api";

const useEditUser = () => {
  const { selectedUser, setSelectedUser } = useContext(SelectedUserContext);

  const [editUser, setEditUser] = useState({});

  useEffect(() => {
    setEditUser({
      name: selectedUser?.name,
      email: selectedUser?.email,
      lastname: selectedUser?.lastName,
      password: selectedUser?.password,
      banned: selectedUser?.banned,
      roles: selectedUser?.roles,
    });
  }, [selectedUser]);

  const handleChange = (e) => {
    setEditUser({
      ...editUser,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckbox = (e) => {
    setEditUser({
      ...editUser,
      [e.target.name]: e.target.checked,
    });
  };

  const handleRoles = (role) => {
    const roles = editUser.roles;
    const index = roles.indexOf(role);
    if (index > -1) {
      roles.splice(index, 1);
    } else {
      roles.push(role);
    }
    setEditUser({
      ...editUser,
      roles,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await api.put(
        `/admin/users/${selectedUser.id}`,
        editUser
      );
      setSelectedUser(data.user);
    } catch (error) {
      console.log(error);
    }
  };

  return { editUser, handleChange, handleCheckbox, handleRoles, handleSubmit };
};

export default useEditUser;
