import React from "react";
import ChestsMenu from "../components/chests/ChestsMenu";
import { ChestsFiltersProvider } from "../context/ChestsFilters";
import AddChest from "../components/chests/AddChest";
import { ChestsProvider } from "../context/Chests";
import ChestsTable from "../components/chests/ChestsTable";

function Chests() {
  const [activeUI, setActiveUI] = React.useState({
    table: true,
    add: false,
  });

  return (
    <ChestsFiltersProvider>
      <ChestsProvider>
        <main className="flex">
          <aside>
            <ChestsMenu setActiveUI={setActiveUI} activeUI={activeUI} />
          </aside>

          <div className="w-full  px-8">
            <header className="flex items-center">
              {/* <UsersStats />
                <UsersFilters /> */}
            </header>
            <section className="w-full flex flex-col md:flex-row gap-16 ">
              {activeUI.table && <ChestsTable />}
              <div className="pt-24">{activeUI.add && <AddChest />}</div>
            </section>
          </div>
        </main>
      </ChestsProvider>
    </ChestsFiltersProvider>
  );
}

export default Chests;
