import { useContext, useEffect, useState } from "react";
import { ChestContext } from "../context/Chest";
import api from "../lib/api";
import { toast } from "sonner";

const useEditChest = () => {
  const { chest, setChest } = useContext(ChestContext);
  const [editChest, setEditChest] = useState(null);

  useEffect(() => {
    setEditChest({
      name: chest?.name,
      price: chest?.price,
      status: chest?.status,
    });
  }, [chest]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditChest({ ...editChest, [name]: value });
  };

  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    setEditChest({ ...editChest, [name]: checked });
  };

  const handleFile = (e) => {
    const { name, files } = e.target;
    setEditChest({ ...editChest, [name]: files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (editChest.name === "" || editChest.price === "") {
      toast.error("Todos los campos son obligatorios");
      return;
    }

    const formData = new FormData();
    formData.append("name", editChest.name);
    formData.append("price", editChest.price);
    formData.append("status", editChest.status);
    if (editChest.img) {
      formData.append("img", editChest.img);
    }

    try {
      const result = await api.put(`/admin/chests/${chest.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success("Cofre actualizado");
      setChest(result.data.chest);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return {
    editChest,
    setEditChest,
    handleChange,
    handleSubmit,
    handleFile,
    handleCheckbox,
  };
};

export default useEditChest;
