import React from "react";
import { AiOutlineEdit } from "react-icons/ai";

import { HiOutlineKey } from "react-icons/hi";

function ChestMenu({ setActiveUI, activeUI }) {
  return (
    <ul className="menu menu-vertical  rounded-box">
      <li>
        <span>Interfaz</span>
      </li>
      <li>
        <span
          onClick={() => setActiveUI({ ...activeUI, keys: !activeUI.keys })}
          className={activeUI.keys && "text-green-500"}
        >
          <HiOutlineKey color="" className="h-5 w-5" />
          Claves
        </span>
      </li>

      <li>
        <span
          onClick={() => setActiveUI({ ...activeUI, edit: !activeUI.edit })}
          className={activeUI.edit && "text-green-500"}
        >
          <AiOutlineEdit color="" className="h-5 w-5" />
          Editar
        </span>
      </li>
    </ul>
  );
}

export default ChestMenu;
