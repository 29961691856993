import React from "react";
import ManualPayment from "./ManualPayment";
import { ORDER_STATUS_CONST, PAYMENT_METHOD } from "../../lib/const";
import { FaExclamationCircle } from "react-icons/fa";
import { Snippet } from "@nextui-org/react";
import PayPalPayment from "./PayPalPayment";
import StripePayment from "./StripePayment";
import Totals from "../checkout/Totals";

function Payment({ order, setOrder }) {
  switch (order.status) {
    case ORDER_STATUS_CONST.WAITING_PAYMENT:
      return <WaitinPayment order={order} />;
      case ORDER_STATUS_CONST.WAITING_DEALER_PAYMENT:
      return <WaitinPayment order={order} />;
    case ORDER_STATUS_CONST.CREATED:
      return null;

    default:
      return <PaymentInfo order={order} setOrder={setOrder} />;
  }
}

function WaitinPayment({order}) {
  return (
    <div className="flex flex-col mt-4 items-center">
      <FaExclamationCircle className="text-warning" size={35} />
      <span>Esperando pago</span>
      <Snippet className="mt-4" >{`${process.env.REACT_APP_FRONTEND_URL}/checkout/${order.id}`}</Snippet>
      <Totals order={order} />
    </div>
  );
}

function PaymentInfo({ order, setOrder }) {
  return (
    <div>
      {parseInt(order.paymentMethod) === PAYMENT_METHOD.MANUAL ? (
        <ManualPayment order={order} setOrder={setOrder} />
      ) : parseInt(order.paymentMethod) === PAYMENT_METHOD.PAYPAL ? (
         <PayPalPayment order={order} />
      ) : (
        <StripePayment order={order} />
      )}
    </div>
  );
}

export default Payment;
