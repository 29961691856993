import { useEffect, useState } from "react";
import api from "../lib/api";

const useDiscounts = () => {
  const [discounts, setDiscounts] = useState([]);

  useEffect(() => {
    const fetchDiscounts = async () => {
      const { data } = await api.get("/admin/discounts");
      setDiscounts(data);
    };
    fetchDiscounts();
  }, []);

  return discounts;
};

export default useDiscounts;
