import moment from "moment";
import "moment/locale/es";

moment.locale("es");

const formatTime = (time) => {
  return moment(time).fromNow();
};

export default formatTime;
