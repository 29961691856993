import React from "react";
import ChatsPanel from "../components/chat/ChatsPanel";

function Chats() {
  return (
    <section className="flex w-3/4 m-auto gap-8">
      <ChatsPanel />

      <div className="w-3/4">
        <div className="flex items-center justify-center">
          <span className="text-gray-400 text-xl font-thin">
            Selecciona un chat
          </span>
        </div>
      </div>
    </section>
  );
}

export default Chats;
