import { useContext, useState } from "react";
import { toast } from "sonner";
import api from "../lib/api";
import { ChestsContext } from "../context/Chests";

const useNewChest = () => {
  const {setChests} = useContext(ChestsContext)
  const [newChest, setNewChest] = useState({
    name: "",
    price: 0,
    file: null,
  });

  const handleChange = (e) => {
    setNewChest({
      ...newChest,
      [e.target.name]: e.target.value,
    });
  };

  const handleFile = (e) => {
    setNewChest({
      ...newChest,
      file: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newChest.name || !newChest.price || !newChest.file) {
      toast.error("Todos los campos son requeridos");
      return;
    }

    const formData = new FormData();
    formData.append("name", newChest.name);
    formData.append("price", newChest.price);
    formData.append("img", newChest.file);

    try {
      const result = await api.post("/admin/chests", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (result.data.success) {
        toast.success("Cofre creado correctamente");
        setChests(prevState => [...prevState, result.data.chest])
        setNewChest({
          name: "",
          price: 0,
          file: null,
        });
        e.target.reset();
      } else {
        toast.error("Ocurrió un error al crear el cofre");
      }
    } catch (error) {
      toast.error("Ocurrió un error al crear el cofre");
    }
  };

  return {
    newChest,
    handleChange,
    handleFile,
    handleSubmit,
  };
};

export default useNewChest;
