import { useEffect, useState } from "react";
import api from "../lib/api";
import { toast } from "sonner";

const useUserStock = (chest, user) => {
  const [stock, setStock] = useState(0);

  useEffect(() => {
    const fetchStock = async () => {
     try {
        const response = await api.get(`/admin/chests/${chest}/stock/${user}`);
        setStock(response.data.stock);
     } catch (error) {
        toast.error(error.message);
     }
    };

    fetchStock();
  }, [chest, user]);

  return stock;
};

export default useUserStock;
