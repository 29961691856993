import { Input } from "@nextui-org/react";
import React from "react";
import { BiRename } from "react-icons/bi";
import { CgRename } from "react-icons/cg";
import useNewDiscount from "../../hooks/useNewDiscount";

function NewDiscount() {
  const { handleSubmit, handleChange } = useNewDiscount();
  return (
    <form onSubmit={handleSubmit} className='flex flex-col gap-4' action=''>
      <Input
        required
        onChange={handleChange}
        type='text'
        name='code'
        label='Código'
        placeholder='Código'
        endContent={<BiRename />}
      />

      <Input
        required
        onChange={handleChange}
        name='discount'
        label='Porcentaje'
        placeholder='Porcentaje'
        endContent={<span>%</span>}
        type='number'
      />

      <Input
        required
        onChange={handleChange}
        name='uses'
        label='Usos'
        placeholder='Usos'
        endContent={<CgRename />}
        type='number'
      />

      <Input
        required
        onChange={handleChange}
        name='expirationDate'
        label='Expira'
        placeholder='Expira'
        type='date'
      />

      <button type='submit' className='btn btn-primary w-full'>
        Crear
      </button>
    </form>
  );
}

export default NewDiscount;
