import React from "react";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownSection,
  DropdownItem,
  Button,
} from "@nextui-org/react";
import useKeyUses from "../../hooks/useKeyUses";
import { Link } from "react-router-dom";
import formatTime from "../../lib/formatTime";

export default function KeyUses({ uses, keyId }) {
  const {keyUses} = useKeyUses(keyId);
  return (
    <Dropdown>
      <DropdownTrigger>
        <Button variant="bordered">Usos ({uses})</Button>
      </DropdownTrigger>
      <DropdownMenu variant="faded" aria-label="Dropdown menu with description">
        <DropdownSection title="Usos" showDivider>
          {keyUses.map((use, index) => (
            <DropdownItem
              key={use.id}
              description={use.id}
            >
             {use.user ? <Link to={`/user/${use.user}`}>
                Usado | {formatTime(use.createdAt)}
              </Link> :
              
              <span>
                No usado
              </span>
              }
            </DropdownItem>
          ))}
        </DropdownSection>
      </DropdownMenu>
    </Dropdown>
  );
}
