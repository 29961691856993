import { useEffect, useState } from "react";
import api from "../lib/api";

const useChat = ({chatId}) => {
  const [chat, setChat] = useState(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchChat = async () => {
      try {
        const { data } = await api.get(`/admin/chats/${chatId}`);
        setChat(data.chat);
      } catch (error) {
        console.log(error);
      }
    };
    fetchChat();
  }, [chatId]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const { data } = await api.get(`/admin/chats/${chatId}/messages`);
        setMessages(data.messages);
      } catch (error) {
        console.log(error);
      }
    };

    if (chat) {
      fetchMessages();
    }
  } , [chatId, chat]);

  return { chat, messages, setMessages };
};


export default useChat;