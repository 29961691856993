import React from "react";
import OrderItem from "./OrderItem";
import { Button } from "@nextui-org/react";
import api from "../../lib/api";
import { toast } from "sonner";

function RequestedKeys({ order }) {
  const placeOrder = async () => {
    try {
      const { data } = await api.put(`/admin/orders/${order.id}/place`);
      const pulling = setInterval(async () => {
        const { data: result } = await api.get(
          `/admin/orders/queue/${data.job}`
        );

        if (result.state === "completed") {
          clearInterval(pulling);
          window.location.reload();
        } else if (result.state === "failed") {
          clearInterval(pulling);
          toast.error(result.message);
        }
      }, 1000);
    } catch (error) {
      toast.error("Error al realizar la compra");
    }
  };

  return (
    <div className="flex bg-gray-900  flex-col items-start gap-4 p-3 rounded-md">
      <span className="text-gray-500">Claves solicitadas: </span>
      <div>
        {order.chests.map((chest) => (
          <OrderItem
            key={chest.id}
            chest={chest}
            customer={order.user.id}
            quantity={
              order.requestedChests.find((key) => key.id === chest.id).quantity
            }
          />
        ))}
      </div>
      <Button
        onClick={() => {
          placeOrder();
        }}
        className="w-full"
        color="warning"
      >
        Enviar claves
      </Button>
    </div>
  );
}

export default RequestedKeys;
