import React from "react";
import EditUser from "../components/user/EditUser";
import UserMenu from "../components/user/UserMenu";
import { SelectedUserProvider } from "../context/SelectedUser";
import IsAuth from "../components/IsAuth";
import UserProfile from "../components/user/UserProfile";
import useUserOrders from "../hooks/useUserOrders";
import { useParams } from "react-router-dom";
import OrderCard from "../components/orders/OrderCard";
import { ORDER_STATUS_CONST } from "../lib/const";

function User() {
  const [activeUI, setActiveUI] = React.useState({
    profile: true,
    edit: false,
  });

  const { id } = useParams();

  const { orders } = useUserOrders({ id });
  const billingTotal = orders
    .filter((order) => order.status === ORDER_STATUS_CONST.PAYED)
    .reduce(
      (acc, order) =>
        acc + (order.subtotal - (order.subtotal * order.discount) / 100),
      0
    ).toFixed(2);

  const pedingBillingTotal = orders.filter((order) => order.status === ORDER_STATUS_CONST.WAITING_DEALER_PAYMENT)
  .reduce(
    (acc, order) =>
      acc + (order.subtotal - (order.subtotal * order.discount) / 100),
    0
  ).toFixed(2); 

  return (
    <>
      <IsAuth />
      <SelectedUserProvider>
        <main className='flex pt-8'>
          <UserMenu activeUI={activeUI} setActiveUI={setActiveUI} />

          <section className='px-8 mt-4 w-full  justify-between'>
            {activeUI.profile && <UserProfile />}
            {activeUI.edit && <EditUser />}

            <div className='grid grid-cols-1 md:grid-cols-2 gap-5 mt-8 w-1/2'>
              <div className='flex flex-col border border-purple-600 rounded-md items-center p-5'>
                <span className='text-2xl'>{orders.length}</span>
                <span className="text-gray-400">Órdenes</span>
              </div>
              <div className='flex flex-col border border-purple-600 rounded-md items-center p-5'>
                <span className='text-2xl'>{billingTotal} USD</span>
                <span className="text-gray-400">Facturado</span>
              </div>
              <div className='flex flex-col border border-purple-600 rounded-md items-center p-5'>
                <span className='text-2xl'>{pedingBillingTotal} USD</span>
                <span className="text-gray-400">Saldo Pendiente</span>
              </div>
            </div>
          </section>
          <section className='md:w-3/4'>
            <header>
              <h4 className='text-3xl font-bold'>Órdenes</h4>
              <p className='text-gray-400 text-xs'>
                Las órdenes que el usuario ha realizado apareceran aqui.
              </p>
            </header>
            <div className='mt-8'>
              {orders.map((order) => (
                <OrderCard key={order.id} order={order} />
              ))}
            </div>
          </section>
        </main>
      </SelectedUserProvider>
    </>
  );
}

export default User;
