import { useEffect, useState } from "react";
import api from "../lib/api";

const useOrder = (id) => {
  const [order, setOrder] = useState(null);

  useEffect(() => {
    const fetchOrder = async () => {
      const { data } = await api.get(`/admin/orders/${id}`);
      setOrder(data.order);
    };
    fetchOrder();
  }, [id]);

  useEffect(() => {
    const fetchOrderChests = async () => {
      const { data } = await api.get(`/admin/orders/${id}/chests`);
      setOrder((prevOrder) => ({ ...prevOrder, chests: data.chests }));
    };
    fetchOrderChests();
  }, [id]);

  return { order, setOrder };
};

export default useOrder;
