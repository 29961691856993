import { Input } from "@nextui-org/react";
import React from "react";
import { BiRename } from "react-icons/bi";
import { CgRename } from "react-icons/cg";
import { HiOutlineMail } from "react-icons/hi";
import useNewUser from "../../hooks/useNewUser";

function CreateUser() {
  const { handleSubmit, handleChange } = useNewUser();
  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4" action="">
      <Input
        onChange={handleChange}
        type="text"
        name="name"
        label="Nombre"
        placeholder="Nombre"
        endContent={<BiRename />}
      />

      <Input
        onChange={handleChange}
        name="lastname"
        label="Apellidos"
        placeholder="Apellidos"
        endContent={<CgRename />}
        type="text"
      />

      <Input
        onChange={handleChange}
        name="email"
        label="Correo"
        placeholder="Correo"
        endContent={<HiOutlineMail />}
        type="text"
      />

      <button  type="submit" className="btn btn-primary w-full">Crear</button>
    </form>
  );
}

export default CreateUser;
