import { useEffect, useState } from "react";
import api from "../lib/api";

const useUsers = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const { data } = await api.get(`/admin/users`);
      setUsers(data.users);
    };

    fetchUsers();
  }, []);

  return { users };
};

export default useUsers;
