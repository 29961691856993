import React, { useContext } from 'react'
import { UserContext } from '../context/User'
import { Navigate } from 'react-router-dom'

function IsAuth() {
    const {user} = useContext(UserContext)
   
    return !user ? <Navigate to="/login" /> : null
}

export default IsAuth