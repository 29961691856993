import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../context/User";
import { BsChat } from "react-icons/bs";
import { ChatContext } from "../context/Chats";

function Navbar() {
  const { user, logout } = useContext(UserContext);
  const {newMessagesCount} = useContext(ChatContext);

  return (
    <div className="navbar ">
      <div className="navbar-start">
        <div className="dropdown">
          <label tabIndex={0} className="btn btn-ghost lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </label>
          <ul
            tabIndex={0}
            className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52"
          >
            <li>
              <Link>Usuarios</Link>
            </li>
            <li>
              <Link to={"/panel"}>Panel</Link>
              <ul className="p-2">
                <li>
                  <Link>Submenu 1</Link>
                </li>
                <li>
                  <Link>Submenu 2</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link>Item 3</Link>
            </li>
          </ul>
        </div>
        <Link className="btn btn-ghost normal-case text-xl">Biocdkeys</Link>
      </div>
      <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal px-1">
          <li>
            <Link to={"/panel"}>Panel</Link>
          </li>
          <li>
            <Link to={"/users"}>Usuarios</Link>
          </li>

          <li tabIndex={0}>
            <details>
              <summary>Facturación</summary>
              <ul className="p-2 z-40 bg-black">
                <li>
                  <Link to={"/orders"}>Ordenes</Link>
                </li>
                <li>
                  <Link to={"/order/new"}>Crear</Link>
                </li>
                <li>
                  <Link to={"/discounts"}>Descuentos</Link>
                </li>
              </ul>
            </details>
          </li>
          <li>
            <Link to={"/chests"}>Cofres</Link>
          </li>
          <li>
            <Link to={"/chats"}>Chats</Link>
          </li>
        </ul>
      </div>
      {user ? (
        <div  className="navbar-end flex gap-4">
          <Link to={"/chats"} className="relative">
            {newMessagesCount() ? <span className="text-xs font-normal text-white text-center bg-red-500 rounded-full h-4 w-4 absolute -top-2 ">
              1
            </span> : null}
            <BsChat size={20} />
          </Link>
          <Link onClick={() => logout()} className="btn">Salir</Link>
        </div>
      ) : (
        <div className="navbar-end">
          <Link to={"/login"} className="btn btn-ghost">
            Iniciar sesión
          </Link>
        </div>
      )}
    </div>
  );
}

export default Navbar;
