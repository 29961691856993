import { createChart } from "lightweight-charts";
function startBillingChart(propOrders) {
  function createSimpleSwitcher(items, activeItem, activeItemChangedCallback) {
    var switcherElement = document.createElement("div");
    switcherElement.classList.add("switcher");

    var intervalElements = items.map(function (item) {
      var itemEl = document.createElement("button");
      itemEl.innerText = item;
      itemEl.classList.add("switcher-item");
      itemEl.classList.toggle("switcher-active-item", item === activeItem);
      itemEl.addEventListener("click", function () {
        onItemClicked(item);
      });
      switcherElement.appendChild(itemEl);
      return itemEl;
    });

    function onItemClicked(item) {
      if (item === activeItem) {
        return;
      }

      intervalElements.forEach(function (element, index) {
        element.classList.toggle("switcher-active-item", items[index] === item);
      });

      activeItem = item;

      activeItemChangedCallback(item);
    }

    return switcherElement;
  }

  var intervals = ["1D", "1W", "1M", "1Y"];

  // group orders by day

  const ordersByDate = propOrders.reduce((acc, order) => {
    const date = order.createdAt.split("T")[0]; // Obtener solo la parte de la fecha, sin la hora

    if (acc[date]) {
      acc[date].value += order.subtotal - (order.subtotal * order.discount / 100); // Incrementar el contador para esa fecha
    } else {
      acc[date] = { time: date, value: order.subtotal - (order.subtotal * order.discount / 100) }; // Inicializar el contador en 1 para esa fecha
    }

    return acc;
  }, {});


  // Convertir el objeto a un array de objetos
  const orders = Object.values(ordersByDate).reverse();

  // filter created this day
  var dayData = orders.filter(
    (user) => user.time === new Date().toISOString().split("T")[0]
  );
  var weekData = orders.filter((user) => {
    const today = new Date();
    const oneWeekAgo = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 7
    );
    const userDate = new Date(user.time);
    return userDate >= oneWeekAgo;
  });

  var monthData = orders.filter((user) => {
    const today = new Date();
    const oneMonthAgo = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      today.getDate()
    );
    const userDate = new Date(user.time);
    return userDate >= oneMonthAgo;
  });

  var yearData = orders.filter((user) => {
    const today = new Date();
    const oneYearAgo = new Date(
      today.getFullYear() - 1,
      today.getMonth(),
      today.getDate()
    );
    const userDate = new Date(user.time);
    return userDate >= oneYearAgo;
  });


  var seriesesData = new Map([
    ["1D", dayData],
    ["1W", weekData],
    ["1M", monthData],
    ["1Y", yearData],
  ]);

  var switcherElement = createSimpleSwitcher(
    intervals,
    intervals[0],
    syncToInterval
  );

  var chartElement = document.createElement("div");

  var chart = createChart(chartElement, {
    width: 800,
    height: 400,
    layout: {
      background: {
        type: "solid",
        color: "#000000",
      },
      textColor: "#d1d4dc",
    },
    grid: {
      vertLines: {
        visible: false,
      },
      horzLines: {
        color: "rgba(42, 46, 57, 0.5)",
      },
    },
    rightPriceScale: {
      borderVisible: false,
    },
    timeScale: {
      borderVisible: false,
    },
    crosshair: {
      horzLine: {
        visible: false,
      },
    },
  });

  const renderTo = document.getElementById("billing-chart");
 
  renderTo.innerHTML = "";
  renderTo.appendChild(chartElement);
  renderTo.appendChild(switcherElement);

  var areaSeries = null;

  function syncToInterval(interval) {
    if (areaSeries) {
      chart.removeSeries(areaSeries);
      areaSeries = null;
    }
    areaSeries = chart.addAreaSeries({
      topColor: "rgba(76, 175, 80, 0.56)",
      bottomColor: "rgba(76, 175, 80, 0.04)",
      lineColor: "rgba(76, 175, 80, 1)",
      lineWidth: 2,
    });
    areaSeries.setData(seriesesData.get(interval));
  }

  syncToInterval(intervals[0]);
}

export default startBillingChart;
