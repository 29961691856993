import { createContext } from "react";
import { useParams } from "react-router-dom";
import useChestKeys from "../hooks/useChestKeys";

const ChestKeysContext = createContext();

const ChestKeysProvider = ({ children }) => {
  const { id } = useParams();
  const { keys, loading, setKeys } = useChestKeys(id);
  return (
    <ChestKeysContext.Provider value={{keys, setKeys, loading}}>{children}</ChestKeysContext.Provider>
  );
};

export { ChestKeysContext, ChestKeysProvider };
