import { Button } from "@nextui-org/react";
import React, { useState } from "react";
import { ORDER_STATUS_CONST } from "../../lib/const";
import { Link } from "react-router-dom";
import usePayment from "../../hooks/usePayment";
import api from "../../lib/api";
import { toast } from "sonner";

function ManualPayment({ order, setOrder }) {
  const { payment } = usePayment(order.paymentId);
  const [confirmPaymentConfirm, setConfirmPaymentConfirm] = useState(false);

  const subtotal = order.subtotal;
  const discount = (order.discount / 100) * subtotal;
  const total = subtotal - discount;

  const confirmPayment = async () => {
    try {
      const { data } = await api.put(`/admin/checkout/${order.id}/confirm`);
      setOrder((prev) => ({
        ...prev,
        status: parseInt(data.order.order.status),
      }));
    } catch (error) {
      toast.error(error.message);
    }
  };

  if (!payment) return null;

  return (
    <div className="flex flex-col">
      <ul>
        <li className="flex gap-3 items-center justify-between">
          <strong className="text-primary">Método de pago:</strong>
          <span>Transferencia/SINPE</span>
        </li>

        <li className="flex gap-3 items-center justify-between">
          <strong className="text-primary">Comprobante:</strong>
          <Link
            to={process.env.REACT_APP_CDN_URL + "/" + payment.voucher}
            className="text-blue-500 underline"
          >
            Ver comprobante
          </Link>
        </li>

        <li className="flex gap-3 items-center justify-between">
          <strong className="text-primary">Subtotal:</strong>
          <span> $ {order.subtotal}</span>
        </li>

        <li className="flex gap-3 items-center justify-between">
          <strong className="text-primary">Descuento:</strong>
          <span> -$ {discount.toFixed(2)}</span>
        </li>

        <li className="flex gap-3 items-center justify-between">
          <strong className="text-primary">Total:</strong>
          <span> $ {total.toFixed(2)}</span>
        </li>
      </ul>

      {(order.status === ORDER_STATUS_CONST.WAITING_PAYMENT_APROVE ||
        order.status ===
          ORDER_STATUS_CONST.WAITING_PAYMENT_APROVE_AND_STOCK) && (
        <>
          {!confirmPaymentConfirm ? (
            <Button
              onPress={() => setConfirmPaymentConfirm(true)}
              className="mt-4"
              color="warning"
            >
              Confirmar pago
            </Button>
          ) : (
            <Button
              onPress={() => confirmPayment()}
              className="mt-4"
              color="warning"
            >
              ¿Estás seguro?
            </Button>
          )}
        </>
      )}
    </div>
  );
}

export default ManualPayment;
