import api from "../lib/api";

const { createContext, useState, useEffect } = require("react");

const OrdersContext = createContext();

const OrdersProvider = ({ children }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getOrders = async () => {
      const { data } = await api.get("/admin/orders");
      setOrders(data.orders);
      setLoading(false);
    };
    getOrders();
  }, []);

  return (
    <OrdersContext.Provider value={{ orders, loading }}>
      {children}
    </OrdersContext.Provider>
  );
};

export { OrdersContext, OrdersProvider };
