import React from "react";
import UsersMenu from "../components/users/UsersMenu";
import UsersStats from "../components/users/UsersStats";
import IsAuth from "../components/IsAuth";
import CreateUser from "../components/users/CreateUser";
import { UsersProvider } from "../context/Users";
import UsersTable from "../components/users/UsersTable";
import UsersFilters from "../components/users/UsersFilters";
import { UsersFiltersProvider } from "../context/UsersFilters";

function Users() {
  const [activeUI, setActiveUI] = React.useState({
    add: false,
    table: true,
    edit: false,
  });

  return (
    <>
      <IsAuth />
      <UsersFiltersProvider>
        <UsersProvider>
          <main className="flex">
            <aside>
              <UsersMenu setActiveUI={setActiveUI} activeUI={activeUI} />
            </aside>

            <div className="w-full  px-8">
              <header className="flex items-center">
                <UsersStats />
                <UsersFilters />
              </header>
              <section className="w-full flex flex-col md:flex-row gap-16 ">
                {activeUI.table && <UsersTable />}
                <div className="pt-24">{activeUI.add && <CreateUser />}</div>
              </section>
            </div>
          </main>
        </UsersProvider>
      </UsersFiltersProvider>
    </>
  );
}

export default Users;
