import { useEffect, useState } from "react";
import api from "../lib/api";

const useChests = () => {
  const [chests, setChests] = useState([]);

  useEffect(() => {
    const fetchChests = async () => {
      const { data } = await api.get("/admin/chests");
      setChests(data);
    };
    fetchChests();
  }, []);

  return chests;
};

export default useChests;
