import { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import api from "../lib/api";

const ChestContext = createContext();

const ChestProvider = ({ children }) => {
  const [chest, setChest] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const getChest = async () => {
      try {
        const result = await api.get(`/admin/chests/${id}`);
        setChest(result.data);
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    getChest();
  }, [id]);

  return (
    <ChestContext.Provider value={{ chest, setChest }}>
      {children}
    </ChestContext.Provider>
  );
};

export { ChestContext, ChestProvider };
