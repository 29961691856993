import { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../lib/api";

const SelectedUserContext = createContext();

const SelectedUserProvider = ({ children }) => {
    const {id} = useParams();
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        if (!id) {
          return;
        }
    
        const getUser = async () => {
          const { data } = await api.get(`/admin/users/${id}`);
          setSelectedUser(data.user);
        };
    
        getUser();
      }, [id]);
    
    
    return (
        <SelectedUserContext.Provider value={{ selectedUser, setSelectedUser }}>
        {children}
        </SelectedUserContext.Provider>
    );
    }

export { SelectedUserContext, SelectedUserProvider };

