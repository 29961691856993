import { createContext, useState } from "react";

const UsersFiltersContext = createContext();

const UsersFiltersProvider = ({ children }) => {
  const [usersFilters, setUsersFilters] = useState({
    search: "",
    role: "all",
    banned: false,
  });

  return (
    <UsersFiltersContext.Provider value={{ setUsersFilters, usersFilters }}>
      {children}
    </UsersFiltersContext.Provider>
  );
};

export { UsersFiltersProvider, UsersFiltersContext };
