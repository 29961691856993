const { useState, useEffect } = require("react");
const { default: api } = require("../lib/api");

const useChestKeys = (chest) => {
  const [keys, setKeys] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getKeys = async () => {
      const result = await api.get(`/admin/chests/${chest}/key`);
      setKeys(result.data);
      setLoading(false);
    };
    if (chest) {
      getKeys();
    }
  }, [chest]);

  return { keys, loading, setKeys };
};

export default useChestKeys;
