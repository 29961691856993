import React from "react";
import IsAuth from "../components/IsAuth";
import UsersChart from "../components/panel/UsersChart";
import BillingChart from "../components/panel/BillingChart";

function Panel() {
  return (
    <>
      <IsAuth />
      <main className="p-8 ">
       <section className="flex justify-between">
       <div>
          <h5>Usuarios</h5>
          <UsersChart />
        </div>

        <div>
          <h5>Ingresos</h5>
          <BillingChart />
        </div>
       </section>
      </main>
    </>
  );
}

export default Panel;
