import { createContext, useState } from "react";

const ChestsFiltersContext = createContext();

const ChestsFiltersProvider = ({ children }) => {
  const [chestsFilters, setChestsFilters] = useState({
    search: "",
    active: true,
  });
  return (
    <ChestsFiltersContext.Provider value={{ chestsFilters, setChestsFilters }}>
      {children}
    </ChestsFiltersContext.Provider>
  );
};

export { ChestsFiltersContext, ChestsFiltersProvider };
