import { Input, User } from "@nextui-org/react";
import React from "react";
import useUserStock from "../../hooks/useUserStock";

function OrderItem({ chest, handleKeyQuantity, customer, quantity }) {
  const stock = useUserStock(chest.id, customer);
  return (
    <div className="flex w-full justify-between mb-4 ">
      <User
        className=""
        key={chest.id}
        name={chest.name}
        description={"Stock: " + stock}
        avatarProps={{
          size: "sm",
          src: process.env.REACT_APP_CDN_URL + "/" + chest.imgId,
        }}
      />

      <Input
      errorMessage={stock < quantity ? "No stock" : ""}
        isRequired
        className="w-2/6"
        type="number"
        name="quantity"
        value={quantity}
        readOnly={handleKeyQuantity === undefined}
        onInput={(e) => {
          handleKeyQuantity(chest.id, e.target.value);
        }}
      />
    </div>
  );
}

export default OrderItem;
