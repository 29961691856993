import { Input } from "@nextui-org/react";
import React, { useContext } from "react";
import { BiRename } from "react-icons/bi";
import { CgRename } from "react-icons/cg";
import { HiOutlineMail } from "react-icons/hi";
import { ROLES } from "../../lib/const";
import { AiOutlineUser } from "react-icons/ai";
import { PiFolderUserDuotone } from "react-icons/pi";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { SelectedUserContext } from "../../context/SelectedUser";
import useEditUser from "../../hooks/useEditUser";

function EditUser() {
  const { selectedUser } = useContext(SelectedUserContext);

  const { handleSubmit, handleChange, handleCheckbox, handleRoles } = useEditUser();

  if (!selectedUser) return null;

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4" action="">
      <Input
        onChange={handleChange}
        type="text"
        name="name"
        label="Nombre"
        placeholder="Nombre"
        endContent={<BiRename />}
        defaultValue={selectedUser.name}
      />

      <Input
        onChange={handleChange}
        name="lastname"
        label="Apellidos"
        placeholder="Apellidos"
        endContent={<CgRename />}
        defaultValue={selectedUser.lastName}
        type="text"
      />

      <Input
        onChange={handleChange}
        name="email"
        label="Correo"
        placeholder="Correo"
        endContent={<HiOutlineMail />}
        defaultValue={selectedUser.email}
        type="text"
      />

      <div className="flex items-center gap-2">
        <span>Baneado</span>
        <input
          onInput={handleCheckbox}
          type="checkbox"
          name="banned"
          defaultChecked={selectedUser.banned}
          className="checkbox"
        />
      </div>

      <div className="flex gap-2">
        <AiOutlineUser
        onClick={() => handleRoles(ROLES.USER)}
          className={
            selectedUser.roles.includes(ROLES.USER)
              ? "text-orange-500"
              : "text-gray-500"
          }
        />
        <PiFolderUserDuotone
        onClick={() => handleRoles(ROLES.DEALER)}
          className={
            selectedUser.roles.includes(ROLES.DEALER)
              ? " text-green-600"
              : "text-gray-500"
          }
        />
        <MdOutlineAdminPanelSettings
        onClick={() => handleRoles(ROLES.ADMIN)}
          className={
            selectedUser.roles.includes(ROLES.ADMIN)
              ? " text-purple-600"
              : "text-gray-500"
          }
        />
      </div>

      <button type="submit" className="btn btn-primary w-full">
        Editar
      </button>
    </form>
  );
}

export default EditUser;
