import { useState } from "react";
import api from "../lib/api";
import { toast } from "sonner";

const useDecryptKey = (keyEncrypted) => {
  const [decriptedKey, setDecriptedKey] = useState(null);

  const decryptKey = async () => {
    try {
      const result = await api.get(`/admin/key/decrypt/${keyEncrypted}`);
      setDecriptedKey(result.data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const encryptKey = () => {
    setDecriptedKey(null);
  };

  return { decriptedKey, decryptKey, encryptKey };
};

export default useDecryptKey;