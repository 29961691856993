import { Input } from "@nextui-org/react";
import React, { useContext } from "react";
import { BiRename } from "react-icons/bi";

import { ChestContext } from "../../context/Chest";
import { MdOutlinePriceCheck } from "react-icons/md";
import useEditChest from "../../hooks/useEditChest";

function EditChest() {
  const { chest } = useContext(ChestContext);

  const { handleSubmit, handleChange, handleCheckbox, handleFile } =
    useEditChest();

  if (!chest) return null;

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4" action="">
      <Input
        onChange={handleChange}
        type="text"
        name="name"
        label="Nombre"
        placeholder="Nombre"
        endContent={<BiRename />}
        defaultValue={chest.name}
      />

      <Input
        onChange={handleChange}
        type="decimal"
        name="price"
        label="Precio"
        placeholder="Precio"
        endContent={<MdOutlinePriceCheck />}
        defaultValue={chest.price}
      />

      <input
        onInput={handleFile}
        type="file"
        name="img"
        className="file-input w-full max-w-xs"
      />

      <div className="flex items-center gap-2">
        <span>Activo</span>
        <input
          onInput={handleCheckbox}
          type="checkbox"
          name="status"
          defaultChecked={chest.status}
          className="checkbox"
        />
      </div>

      <button type="submit" className="btn btn-primary w-full">
        Editar
      </button>
    </form>
  );
}

export default EditChest;
