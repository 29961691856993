import React, { useContext } from "react";
import { FiUsers } from "react-icons/fi";
import { HiUserAdd } from "react-icons/hi";
import { PiFolderUserDuotone } from "react-icons/pi";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import { UsersFiltersContext } from "../../context/UsersFilters";
import { ROLES } from "../../lib/const";
import { FaExclamationCircle } from "react-icons/fa";
function UsersMenu({ setActiveUI, activeUI }) {
  const { setUsersFilters, usersFilters } = useContext(UsersFiltersContext);
  return (
    <ul className="menu menu-vertical  rounded-box">
      <li>
        <span>Interfaz</span>

        <span
          onClick={() => setActiveUI({ ...activeUI, table: !activeUI.table })}
          className={activeUI.table && "text-green-500"}
        >
          <FiUsers className="h-5 w-5" />
          Usuarios
        </span>
      </li>
      <li>
        <span
          onClick={() => setActiveUI({ ...activeUI, add: !activeUI.add })}
          className={activeUI.add && "text-green-500"}
        >
          <HiUserAdd color="" className="h-5 w-5" />
          Agregar
        </span>
      </li>

      <li>
        <span>Roles</span>

        <div className="flex flex-col items-start gap-2">
          <div
            className="flex items-center  gap-2"
            onClick={() =>
              setUsersFilters((prev) => ({ ...prev, role: ROLES.USER }))
            }
          >
            <AiOutlineUser className={usersFilters.role === ROLES.USER && "text-orange-500"} />
            <span>Usuario</span>
          </div>

          <div
            onClick={() =>
              setUsersFilters((prev) => ({ ...prev, role: ROLES.DEALER }))
            }
            className="flex items-center  gap-2"
          >
            <PiFolderUserDuotone className={usersFilters.role === ROLES.DEALER && "text-green-500"} />
            <span>Mayorista</span>
          </div>

          <div
            onClick={() =>
              setUsersFilters((prev) => ({ ...prev, role: ROLES.ADMIN }))
            }
            className="flex items-center  gap-2"
          >
            <MdOutlineAdminPanelSettings className={usersFilters.role === ROLES.ADMIN && "text-purple-500"}/>

            <span>Admin</span>
          </div>
        </div>
      </li>

      <li>
        <span>Baneado</span>

          <div
            className="flex items-center  gap-2"
            onClick={() =>
              setUsersFilters((prev) => ({ ...prev, banned: !prev.banned }))
            }
          >
            <FaExclamationCircle className={usersFilters.banned && "text-red-500"} />
            <span>Baneado</span>
          </div>
      </li>
    </ul>
  );
}

export default UsersMenu;
