import { useEffect, useState } from "react";
import api from "../lib/api";

const useOrder = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const getOrders = async () => {
      const { data } = await api.get("/admin/orders");
      setOrders(data.orders);
    };
    getOrders();
  }, []);

  return { orders };
};

export default useOrder;