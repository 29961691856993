import { useEffect } from "react";
import { createContext } from "react";
import chatSocket from "../lib/chatSocket";
import useChats from "../hooks/useChats";
import { useCallback } from "react";

const ChatContext = createContext();

const ChatProvider = ({ children }) => {
  const { chats, setChats } = useChats();

  useEffect(() => {
    chatSocket.on("connect", () => {
      console.log("Conectado al servidor de chat");
    });

    chatSocket.on("disconnect", () => {
      console.log("Desconectado del servidor de chat");
    });

    chatSocket.on("message", (message) => {
      // update the chats context
      setChats((prevChats) => {
        const newChats = [...prevChats];
        const chatIndex = newChats.findIndex(
          (chat) => chat.id === message.chatId
        );
        newChats[chatIndex].adminReaded = 0;
        newChats[chatIndex].lastMessage = message;
        return newChats;
      });
    });
  }, [setChats]);

  const newMessagesCount = () => {
    return chats.reduce((acc, chat) => {
      if (chat.adminReaded === 0) {
        return acc + 1;
      }
      return acc;
    }, 0);
  };

  const markAsRead = useCallback(
    (chatId) => {
        setChats((prevChats) => {
          const newChats = [...prevChats];
          const chatIndex = newChats.findIndex((chat) => chat.id === chatId);
          if (chatIndex !== -1) {
            newChats[chatIndex].adminReaded = 1;
          }
          return newChats;
        });
    },
    [setChats]
  );

  return (
    <ChatContext.Provider value={{ chats, newMessagesCount, markAsRead }}>
      {children}
    </ChatContext.Provider>
  );
};

export { ChatContext, ChatProvider };
