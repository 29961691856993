import React from "react";
import useOrder from "../hooks/useOrder";
import { useParams } from "react-router-dom";
import { User } from "@nextui-org/react";
import { ORDER_STATUS, ORDER_STATUS_CONST } from "../lib/const";
import Payment from "../components/order/Payment";
import RequestedKeys from "../components/order/RequestedKeys";
import OrderKeys from "../components/order/OrderKeys";

function Order() {
  const { id } = useParams();
  const { order, setOrder } = useOrder(id);

  if (!order || !order.chests) return null;

  return (
    <main className='px-5'>
      <header className='w-full flex flex-col justify-start  mt-12 bg-gray-950 p-3'>
        <User
          name={order.user.name}
          avatar={order.user.avatar}
          description={order.user.email}
          className='w-fit'
        />

        <span
          className={`text-${ORDER_STATUS[order.status].color} text-xs pl-12`}
        >
          {ORDER_STATUS[order.status].name}
        </span>
      </header>

      <section className=' flex justify-start gap-8 p-3 w-full mt-12'>
        {(order.status === ORDER_STATUS_CONST.PAYED || order.status === ORDER_STATUS_CONST.WAITING_DEALER_PAYMENT)&& (
          <OrderKeys order={order} />
        )}
        {order.status === ORDER_STATUS_CONST.WAITING_STOCK &&  (
          <RequestedKeys order={order} />
        )}

        <div className='flex flex-col max-h-screen overflow-y-scroll'>
          {(order.status !== ORDER_STATUS_CONST.WAITING_STOCK &&
            order.status !== ORDER_STATUS_CONST.PAYED && order.status !== ORDER_STATUS_CONST.WAITING_DEALER_PAYMENT) &&
            order.chests.map((chest) => (
              <div className='bg-[#181818]  px-10 py-6 rounded-lg md:max-w-[250px] min-h-[300px]'>
                <img
                  alt={chest.name}
                  src={process.env.REACT_APP_CDN_URL + "/" + chest.imgId}
                  className='  md:max-h-[250px]  rounded-xl'
                />
                <div className='mt-5'>
                  <h6 className='font-bold text-sm'>{chest.name}</h6>
                  <div className='flex gap-4 items-center mt-2'>
                    <span>{chest.price} USD</span>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className='w-fit'>
          <Payment order={order} setOrder={setOrder} />
        </div>
      </section>
    </main>
  );
}

export default Order;
