import { createContext, useEffect, useState } from "react";
import api from "../lib/api";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState("loading");
  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await api.get("/user");
        setUser(res.data);
      } catch (error) {
        setUser(null);
        console.log(error);
      }
    };
    getUser();
  }, []);

  const logout = async () => {
    try {
      await api.post("/user/logout");
      setUser(null);
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <UserContext.Provider value={{ user, setUser, logout }}>{children}</UserContext.Provider>
  );
};

export { UserProvider, UserContext };
