import React from "react";
import { useContext } from "react";
import { ChatContext } from "../../context/Chats";

import { User } from "@nextui-org/react";
import formatTime from "../../lib/formatTime";
function ChatsPanel() {
  const { chats } = useContext(ChatContext);

  return (
    <div className="w-1/4">
      {chats.map((chat) => (
        <div
          onClick={() => window.location.href = `/chats/${chat.id}`}
          key={chat.id}
          className="relative shadow-md rounded-md p-4 bg-[#191919] cursor-pointer hover:bg-[#292929]"
        >
          <User
            name={chat.name + " " + chat.lastName}
            description={chat.lastMessage?.message}
          />
          <div className="flex  ">
            <span className="text-xs text-gray-400 font-thin pl-12">
              {formatTime(chat.lastMessage?.createdAt)}
            </span>
          </div>
          {!chat.adminReaded && (
            <div className="absolute top-2 left-2 z-40  bg-red-700 rounded-full w-[25px] h-[25px] text-center">
              1
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default ChatsPanel;
