import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/table";
import { User } from "@nextui-org/react";
import React, { useContext } from "react";

import formatTime from "../../lib/formatTime";
import { Link } from "react-router-dom";
import { OrdersContext } from "../../context/Orders";
import { ORDER_STATUS, PAYMENT_METHOD } from "../../lib/const";

function OrdersTable() {
  const { orders } = useContext(OrdersContext);
  return (
    <Table className="mt-12 h-[60vh] overflow-y-scroll">
      <TableHeader className="">
        <TableColumn className="text-left">N° Orden</TableColumn>
        <TableColumn className="text-left">Cliente</TableColumn>
        <TableColumn className="text-left">Total</TableColumn>
        <TableColumn className="text-left">Estado</TableColumn>
        <TableColumn className="text-left">Método de pago</TableColumn>
        <TableColumn className="text-left">Creado</TableColumn>
      </TableHeader>
      <TableBody>
        {orders &&
          orders.map((order) => (
            <TableRow className={` rounded `} key={order.id}>
              <TableCell className="py-4 small">
                <Link
                  to={`/order/${order.id}`}
                  className="text-blue-500 hover:underline"
                >
                  {order.id}
                </Link>
              </TableCell>

              <TableCell className="py-4 small">
                <User
                  name={order.user.name}
                  description={order.user.email}
                  avatar={order.user.avatar}
                />
              </TableCell>
              <TableCell className="py-4 small">
                $
                {(
                  order.subtotal -
                  (order.subtotal * order.discount) / 100
                ).toFixed(2)}
              </TableCell>

              <TableCell
                className={`py-4 small text-${
                  ORDER_STATUS[order.status].color
                }`}
              >
                {ORDER_STATUS[order.status].name}
              </TableCell>

              <TableCell className={`py-4 small`}>
                {(() => {
                  switch (parseInt(order.paymentMethod)) {
                    case PAYMENT_METHOD.MANUAL:
                      return "Transferencia/SINPE";
                    case PAYMENT_METHOD.PAYPAL:
                      return "Paypal" ;
                    case PAYMENT_METHOD.STRIPE:
                      return "Stripe";
                    default:
                      return "-";
                  }
                })()}
              </TableCell>
              <TableCell className="py-4 small">
                {formatTime(order.createdAt)}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}

export default OrdersTable;
