import React from "react";
import usePayment from "../../hooks/usePayment";

function PayPalPayment({ order, setOrder }) {
  const { payment } = usePayment(order.paymentId);

  const subtotal = order.subtotal;
  const discount = (order.discount / 100) * subtotal;
  const total = subtotal - discount;

  if (!payment) return null;

  return (
    <div className="flex flex-col">
      <ul>
        <li className="flex gap-3 items-center justify-between">
          <strong className="text-primary">Método de pago:</strong>
          <span>PayPal</span>
        </li>

        <li className="flex gap-3 items-center justify-between">
          <strong className="text-primary">Transacción:</strong>
          <span> {order.id}</span>
        </li>

        <li className="flex gap-3 items-center justify-between">
          <strong className="text-primary">Subtotal:</strong>
          <span> $ {order.subtotal}</span>
        </li>

        <li className="flex gap-3 items-center justify-between">
          <strong className="text-primary">Descuento:</strong>
          <span> -${discount.toFixed(2)}</span>
        </li>

        <li className="flex gap-3 items-center justify-between">
          <strong className="text-primary">Total:</strong>
          <span> $ {total.toFixed(2)}</span>
        </li>
      </ul>
    </div>
  );
}

export default PayPalPayment;
