import { useState } from "react";
import api from "../lib/api";
import { toast } from "sonner";

function useNewDiscount() {
  const [discount, setDiscount] = useState({
    code: "",
    uses: "",
    expirationDate: "",
    discount: "",
  });

  const handleChange = (e) => {
    setDiscount({
      ...discount,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      discount.code === "" ||
      discount.expirationDate === "" ||
      discount.uses === "" ||
      discount.discount === ""
    ) {
      toast.error("Todos los campos son obligatorios");
      return;
    }

    try {
      const formatedDiscount = {
        ...discount,
        code: discount.code.toUpperCase(),
        uses: parseInt(discount.uses),
        discount: parseInt(discount.discount),
      };
      await api.post("/admin/discounts", formatedDiscount);
      toast.success("Descuento creado");
      e.target.reset();
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
    }
  };

  return {
    discount,
    handleChange,
    handleSubmit,
  };
}

export default useNewDiscount;
