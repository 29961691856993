import React from "react";
import { PiFolderUserDuotone } from "react-icons/pi";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import { ROLES } from "../../lib/const";
function UserRoles({ roles }) {
    
  return (
    <div className="flex gap-2">
      <AiOutlineUser className={roles.includes(ROLES.USER) ? "text-orange-500" : "text-gray-500"} />
      <PiFolderUserDuotone className={roles.includes(ROLES.DEALER) ? " text-green-600" : "text-gray-500"} />
      <MdOutlineAdminPanelSettings className={roles.includes(ROLES.ADMIN) ? " text-purple-600" : "text-gray-500"} />
    </div>
  );
}

export default UserRoles;
