import { Button, Input } from "@nextui-org/react";
import React from "react";
import { BiRename } from "react-icons/bi";
import { MdOutlinePriceCheck } from "react-icons/md";
import useNewKey from "../../hooks/useNewKey";

function NewKey() {
  const { handleSubmit, handleChange, handleNewKey, newKey, handleNewKeyIndividual, individualKey, handleDeleteKey } = useNewKey();

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col gap-4 w-96"
      action=""
    >
      <ul className="flex flex-col gap-4">
        {newKey.key.map((key, index) => (
          <li key={index} className="flex justify-between">
            <span>{key}</span>

            <button
              onClick={() => handleDeleteKey(key)}
              className="btn hover:bg-red-500 hover:text-white"
              type="button"
            >
              Eliminar
            </button>
            
            </li>
        ))}
      </ul>
      
      <div className="flex gap-3 items-center">
      <Input
        onChange={handleNewKeyIndividual}
        type="password"
        name="key"
        label="Clave"
        placeholder="Clave"
        endContent={<BiRename />}
        value={individualKey}
      />
      <Button
        onClick={handleNewKey}
        className="btn btn-primary"
        size="small"
        color="success"
        variant="contained"
      >
        Agregar
      </Button>
      </div>

      <Input
        onChange={handleChange}
        type="number"
        name="uses"
        label="Usos"
        placeholder="Usos"
        endContent={<MdOutlinePriceCheck />}
      />

      <button type="submit" className="btn btn-primary w-full">
        Crear
      </button>
    </form>
  );
}

export default NewKey;
